// import { zPayForgotPasswordClientForm } from "@zoox-framework/zoox-components";
import zPayForgotPasswordClientForm from "@/components/zPayForgotPasswordClientForm";
import PersonService from "@/services/PersonService";
import PaymentService from "@/services/PaymentService";
import CompanyService from "@/services/CompanyService";
import Vue from "vue";

export default {
  name: "ForgotPasswordClient",
  components: {
    zPayForgotPasswordClientForm
  },
  data() {
    return {
      personService: new PersonService(),
      paymentService: new PaymentService(),
      companyService: new CompanyService(),
      vueInstance: Vue,
      person: {
        companyId: "",
        documentNumber: "",
        documentTypeId: ""
      },
      showMessage: false,
      companyParams: null,
      step: 0
    };
  },
  async mounted() {
    const companyId = this.companyService.getCurrentCompanyId();
    this.person.companyId = companyId;
    this.companyParams = await this.companyService.getFnrhFormSettings(
      companyId
    );
  },
  methods: {
    forgotPassword(personData) {
      this.personService
        .forgotPassword(personData)
        .then(resp => {
          if (resp && resp.passwordToken) {
            this.showMessage = true;
            this.step++;
          }
        })
        .catch(err => {
          if (err.status === 404) {
            this.$toaster.error(this.$t("GLOBAL.PERSON_NOT_FOUND"));
          } else if (err.status === 412) {
            this.$toaster.error(this.$t("PAY_FORGOT_PASSWORD.HAS_NO_PAY"));
          }
        });
    },
    goToAuth() {
      const currentToken = this.paymentService.getChargeToken();
      if (currentToken && currentToken != "null") {
        this.$router.push(`/auth?chargeToken=${currentToken}`);
      } else {
        this.$router.push("/auth");
      }
      this.step = 0;
    }
  }
};
