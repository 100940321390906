import Vue from "vue";
import {
  validationMixin,
  DocumentTypeService
} from "@zoox-framework/smck-service";

export default {
  name: "zPayForgotPasswordClientForm",
  mixins: [validationMixin],
  props: {
    person: {
      type: Object,
      default: () => {
        return {
          documentTypeId: "",
          documentNumber: "",
          companyId: ""
        };
      }
    },
    companyParams: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      requiredDocuments: {
        cpf: true,
        passport: true
      }
    };
  },
  computed: {
    documentTypes: function() {
      return new DocumentTypeService(Vue).getAll(this.requiredDocuments);
    }
  },
  methods: {
    forgotPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        this.$emit("forgot-password", this.person);
      }
    },
    getDocumentMask: function(documentType) {
      switch (documentType) {
        case 1: // CPF
          return "###.###.###-##";
        case 5: // RUT
          return "#######-N";
        default:
          return "";
      }
    },
    getDocumentLength: function(documentType) {
      switch (documentType) {
        case 4: // Passport
          return 12;
        default:
          return 20;
      }
    },
    getDocumentValidation: function(documentType, value) {
      let isValid = true;

      isValid = isValid && this.rules.required(value);

      switch (documentType) {
        case 1: // CPF
          isValid = isValid && this.rules.cpf(value);
          break;
        case 5: // RUT
          isValid = isValid && this.rules.rut(value);
          break;
        default:
          break;
      }

      return isValid;
    }
  }
};
