var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"forgotPasswordForm",attrs:{"id":"forgotPasswordForm"},on:{"submit":function($event){$event.preventDefault();return _vm.forgotPassword.apply(null, arguments)}}},[_c('v-container',{attrs:{"fluid":"","pa-0":""}},[_c('v-layout',{attrs:{"column":"","pa-0":""}},[_c('v-flex',{attrs:{"pb-0":""}},[_c('p',{staticClass:"label-email"},[_vm._v(" "+_vm._s(_vm.$t("PAY_FORGOT_PASSWORD.INSIDE_CARD_MESSAGE"))+" ")])]),_c('v-flex',{attrs:{"pt-0":"","pb-0":""}},[_c('v-select',{staticClass:"CentralPayFormField CentralPayFormFieldRounded personDocumentType",attrs:{"attach":"","item-text":"name","item-value":"value","id":"personDocumentType","value":_vm.person.documentTypeId,"items":_vm.documentTypes,"label":_vm.$t('GLOBAL.DOCUMENT_TYPE'),"rules":[_vm.rules.required]},model:{value:(_vm.person.documentTypeId),callback:function ($$v) {_vm.$set(_vm.person, "documentTypeId", $$v)},expression:"person.documentTypeId"}})],1),_c('v-flex',{attrs:{"pt-0":"","pb-0":""}},[(_vm.person.documentTypeId === 1)?_c('v-text-field',{staticClass:"CentralPayFormField CentralPayFormFieldRounded",attrs:{"id":"personDocumentNumber","pattern":"[0-9]*","inputmode":"numeric","mask":_vm.getDocumentMask(_vm.person.documentTypeId),"maxlength":_vm.getDocumentLength(_vm.person.documentTypeId),"count":_vm.getDocumentLength(_vm.person.documentTypeId),"rules":[function (v) { return _vm.getDocumentValidation(_vm.person.documentTypeId, v); }],"label":_vm.$t('GUEST_FORM.DOCUMENT_NUMBER') + '*'},model:{value:(_vm.person.documentNumber),callback:function ($$v) {_vm.$set(_vm.person, "documentNumber", $$v)},expression:"person.documentNumber"}},[_c('template',{slot:"append"},[(
                _vm.person.documentTypeId &&
                  _vm.getDocumentValidation(
                    _vm.person.documentTypeId,
                    _vm.person.documentNumber
                  ) == true
              )?_c('v-icon',{staticClass:"mt-1",attrs:{"color":"success"}},[_vm._v(" far fa-check-circle ")]):_vm._e()],1)],2):_c('v-text-field',{staticClass:"CentralPayFormField CentralPayFormFieldRounded",attrs:{"id":"personDocumentNumber","mask":_vm.getDocumentMask(_vm.person.documentTypeId),"maxlength":_vm.getDocumentLength(_vm.person.documentTypeId),"count":_vm.getDocumentLength(_vm.person.documentTypeId),"rules":[function (v) { return _vm.getDocumentValidation(_vm.person.documentTypeId, v); }],"label":_vm.$t('GUEST_FORM.DOCUMENT_NUMBER') + '*'},model:{value:(_vm.person.documentNumber),callback:function ($$v) {_vm.$set(_vm.person, "documentNumber", $$v)},expression:"person.documentNumber"}},[_c('template',{slot:"append"},[(
                _vm.person.documentTypeId &&
                  _vm.getDocumentValidation(
                    _vm.person.documentTypeId,
                    _vm.person.documentNumber
                  ) == true
              )?_c('v-icon',{staticClass:"mt-1",attrs:{"color":"success"}},[_vm._v(" far fa-check-circle ")]):_vm._e()],1)],2)],1),_c('v-flex',{attrs:{"pt-0":""}},[_c('v-btn',{staticClass:"btn-action buttonColor buttonColor is-uppercase",attrs:{"block":"","large":"","id":"zPayForgotPasswordClientSubmitForm","type":"submit"}},[_c('span',{staticClass:"buttonTextColor"},[_vm._v(" "+_vm._s(_vm.$t("PAY_FORGOT_PASSWORD.SEND_BUTTON"))+" ")])])],1)],1),_vm._t("go-back-link")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }